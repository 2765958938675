import styled, { css, RuleSet } from 'styled-components';
import { FlexCenterAligned } from '../../../../styled/common';

export const FooterContainer = styled(FlexCenterAligned)<{
  styles?: RuleSet<object>;
}>`
  position: sticky;
  bottom: 0;
  height: 88px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.primary};
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: 15px 20px;
  display: flex;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    justify-content: flex-end;
  }

  ${({ styles }) => styles};
`;

export const baseButtonStyles = css`
  height: 48px;
  span {
    background-color: inherit;
  }

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    width: 173px;
  }
`;
