import { ButtonType } from '../../../../styled/theme/types';
import { Button } from '../../Button';
import { baseButtonStyles, FooterContainer } from './styled';
import { ModalFooterProps } from './types';

export const ModalFooter = ({
  confirmText,
  resetText,
  resetIsDisabled,
  onReset,
  onConfirm,
  resetCypress,
  confirmCypress,
  styles
}: ModalFooterProps) => {
  return (
    <FooterContainer styles={styles}>
      <Button
        disabled={resetIsDisabled}
        type="button"
        text={resetText}
        onClick={onReset}
        state={ButtonType.grey}
        styles={baseButtonStyles}
        cypress={resetCypress}
      />
      <Button
        type="button"
        text={confirmText}
        onClick={onConfirm}
        styles={baseButtonStyles}
        cypress={confirmCypress}
        stat={confirmCypress}
      />
    </FooterContainer>
  );
};
