import styled, { css } from 'styled-components';
import { FlexBetween } from '../../../../styled/common';
import { Typography } from '../../Typography';
import { CloseButtonAlignType } from '../types';

const headerSmallSize = '60px';
const headerBigSize = '65px';

export const NewFiltersHeaderContainer = styled(FlexBetween)<{
  showHeaderBorder: boolean;
}>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.primary};
  height: ${headerSmallSize};
  z-index: 99;
  -webkit-backface-visibility: hidden;
  box-sizing: border-box;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 25px;

    ${({ showHeaderBorder }) =>
      showHeaderBorder &&
      css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
      `}
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    height: ${headerBigSize};
    padding-right: 25px;
    padding-left: 40px;
  }
`;

export const NewFiltersHeaderTitle = styled(Typography)`
  margin: 0;
  flex-grow: 1;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    text-align: left;
    flex-grow: 0;
  }
`;

export const closeButtonStyles = (
  closeButtonAlign?: CloseButtonAlignType
) => css`
  position: absolute;
  ${closeButtonAlign === 'left' ? 'left: 0;' : 'right: 0;'}
  width: ${headerSmallSize};
  height: ${headerSmallSize};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    position: relative;
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    height: ${headerBigSize};
    width: ${headerBigSize};
  }
`;
