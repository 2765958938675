import { Location } from '../../components/NewSearch/NewSearchLocations/types';

export const getChosenLocationNames = (
  locations: Location[],
  chosenLocationIds: string[]
): string[] => {
  const initialArray: string[] = [];

  return locations.reduce(
    (result, { id, name }) =>
      (chosenLocationIds || []).includes(id)
        ? [...result, name.trim()]
        : result,
    initialArray
  );
};
