import { useQuery } from '@apollo/client';
import { LocationScopeEnum } from '../graphql/types';
import { cityAndLocationsQuery } from '../queries';

export const useCityAndLocationsQuery = (
  cityId = '',
  scope = LocationScopeEnum.All,
  limit = 1000,
  skip = false
) => {
  const variables = { cityId, scope, limit };
  return useQuery(cityAndLocationsQuery(variables), { variables, skip });
};
