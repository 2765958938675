import styled, { keyframes, RuleSet } from 'styled-components';
import { FlexCenterAligned } from '../../../styled/common';

const showFromBottom = keyframes`
  0% {
    transform: translateY(120%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ModalBackdrop = styled(FlexCenterAligned)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 70000; // to hide bg-banner
  -webkit-backface-visibility: hidden;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    height: 100vh;
  }
`;

export const ModalWrapper = styled.div<{ styles?: RuleSet<object> }>`
  background-color: white;
  animation: ${showFromBottom} 0.3s forwards ease-in-out;
  overflow: hidden;

  width: 100%;
  height: 100%;
  margin: 0;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    width: 664px;
    height: 880px;
    border-radius: 14px;
    max-width: calc(100% - 80px);
    max-height: calc(100% - 120px);
  }

  @media screen and (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    width: 1250px;
    height: 750px;
    max-width: calc(100% - 190px);
  }

  ${({ styles }) => styles};
`;
