import React, { ReactNode } from 'react';
import { Button } from '../../Button';
import { CloseIcon } from '../../../../icons';
import { palette } from '../../../../styles/_palette';
import {
  closeButtonStyles,
  NewFiltersHeaderContainer,
  NewFiltersHeaderTitle
} from './styled';
import useScreenSize from '../../../../hooks/useScreenSize';
import { ButtonType } from '../../../../styled/theme/types';
import { CloseButtonAlignType } from '../types';

export interface ModalHeaderProps {
  onClose: () => void;
  title: string | ReactNode;
  cypress: string;
  showHeaderBorder: boolean;
  closeButtonAlign?: CloseButtonAlignType;
}

export const ModalHeader = ({
  title,
  onClose,
  cypress,
  showHeaderBorder,
  closeButtonAlign
}: ModalHeaderProps) => {
  const { isSmall } = useScreenSize();

  return (
    <NewFiltersHeaderContainer showHeaderBorder={showHeaderBorder}>
      <Button
        state={ButtonType.link}
        onClick={onClose}
        styles={closeButtonStyles(closeButtonAlign)}
        cypress="new-search-popup-header-btn-close"
      >
        <CloseIcon fill={palette.heatheredGrey} width={15} height={15} />
      </Button>

      <NewFiltersHeaderTitle data-cy={cypress} tag={isSmall ? 'h3' : 'h4_new'}>
        {title}
      </NewFiltersHeaderTitle>
    </NewFiltersHeaderContainer>
  );
};
